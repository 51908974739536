import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import HeroImage from "../components/heroImage"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container">
      <div className="hero-section">
        <div className="head-image">
          {/* <HeroImage/> */}
        </div>
        <div className="hero-content">
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <Link to="/" >Go back to main page</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
